import React, { useEffect } from "react"
import { Link } from "gatsby";
const TopNavigation = ({ siteData, contactData }) => {
  useEffect(() => {
    const { $ } = window
    $(".mean-menu").meanmenu({
      meanScreenWidth: "991",
    })
  }, [])
  return (
    <div className="top-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-12">
            <ul className="header-contact-info">
              {/* <li>
                <i className="far fa-clock"></i> Available 24&times;7
              </li> */}
              <li>
                <i className="fas fa-phone"></i>{" "}
                <a href="#">
                  {contactData.phone}, {contactData.mobile}
                </a>
              </li>
              <li>
                <i className="far fa-paper-plane"></i>{" "}
                <a href="#">{contactData.email}</a>
              </li>
              <li>
                <i className="fa fa-download"></i>{" "}
                <a href="/downloads">Downloads</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12">
            <div className="header-right-content">
              <ul className="top-header-social">

                <li>
                  <a href={contactData.social.facebook}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li> */}
                <li>
                  <a href={contactData.social.instagram}>
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Navigation = props => {
  const { siteData, contactData } = props
  return (
    <header className="header-area" style={{ position: "relative" }}>
      <TopNavigation {...props} />
      <div className="navbar-area">
        <div className="klev-responsive-nav">
          <div className="container">
            <div className="klev-responsive-menu">
              <div className="logo">
                <a href="/">
                  <img src="/img/logo.png" alt="logo" style={{ maxHeight: 60 }} />
                  {/* <br />
                  <span
                    style={{
                      //color: "#fff",
                      textAlign: "justify",
                      marginTop: -8,
                      marginBottom: 8,
                      fontSize: "12px",
                      //letterSpacing: 0.1,
                      display: "block",
                      width: "100%",
                      marginLeft: "5px",
                    }}
                  >
                    {siteData.company_name}
                  </span> */}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="klev-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="/">
                {
                  /* background: rgba(255, 255, 255, 0.9); padding:0px 20px; */
                }
                <img
                  src="/img/logo.png"
                  alt="logo"
                  style={{ marginTop: 8, maxHeight: 60 }}

                />
                <br />
                <span
                  style={{
                    //color: "#fff",
                    textAlign: "justify",
                    marginTop: -8,
                    marginBottom: 8,
                    fontSize: "12px",
                    //letterSpacing: 0.1,
                    display: "block",
                    width: "100%",
                    marginLeft: "5px",
                  }}
                >
                  {siteData.company_name}
                </span>
              </a>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="/" className="nav-link active">
                      HOME
                    </a>
                  </li>

                  {/* <li className="nav-item">
                    <a href="/covid" className="nav-link">
                      COVID 19
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <a href="/facilities" className="nav-link">
                      FACILITIES
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="/team" className="nav-link">
                      TEAM
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="#" className="nav-link">ABOUT<i className="fas fa-chevron-down"></i></a>
                    <ul className="dropdown-menu">
                      <li className="nav-item"><Link to="/about" className="nav-link">Nemcare Tezpur</Link></li>
                      <li className="nav-item"><Link to="/investors_relation" className="nav-link">Investors Relation</Link></li>
                      {/* <li className="nav-item"><Link to="/general_meetings" className="nav-link">General Meetings</Link></li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a href="/contact" className="nav-link">
                      CONTACT
                    </a>
                  </li>
                </ul>
                {/* <div className="others-option">
                  <div className="burger-menu">
                    <i className="flaticon-menu"></i>
                  </div>
                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navigation
