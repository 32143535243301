import React from "react"
import { createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import ContactData from "../../contents/contact_details.yaml"
import Layout from "./Layout"

const GlobalStyle = createGlobalStyle`
  body {
    // color: ${props => (props.theme === "purple" ? "purple" : "white")};
  }
`
function withLayout(Component) {
  return props => {
    const data = useStaticQuery(graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            company_name
            title
            emergency_contact
            description
            site_url
            title_short
            title
            author
            logo_image_url
            preview_image_url
            facebook_page
          }
        }
      }
    `)
    return (
      <>
        <GlobalStyle theme="default" />
        <Layout siteData={data.site.siteMetadata} contactData={ContactData}>
          <Component
            {...props}
            siteData={data.site.siteMetadata}
            contactData={ContactData}
          />
        </Layout>
      </>
    )
  }
}

export default withLayout
