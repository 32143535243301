import React from 'react';
import withLayout from "../layout/withLayout";
import Seo from "../components/Seo";

const data = [
  {
    id: "90e87f68-c62e-49cf-84d6-9a34aec6ab2e",
    title: "Annual Return 2021 - 2022",
    downloadTitle: "Download PDF File",
    url: "https://storage.googleapis.com/itechcom_public/nemcaretezpur/Annual-return_2021-2022-2.pdf",
  },
  {
    id: "de227a5b-c3b5-4a82-8a5e-f3d0db5dd260",
    title: "Annual Return 2020 - 2021",
    downloadTitle: "Download PDF File",
    url: "https://storage.googleapis.com/itechcom_public/nemcaretezpur/Annual%20return_2021-2022.pdf"
  }
]
const DownloadsPage = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title="Downloads" description="News Events and other Downloads" siteMetadata={siteData} />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Downloads</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Downloads</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div
                className={"services-details-desc"}
                style={{ boxShadow: "none" }}
              >
                <h3>Reports, Events and Downloads</h3>
                <table className={"description_table mt-4"}>
                  <tbody>
                    {
                      data.map(d => (
                        <tr key={d.id}>
                          <td style={{ width: 50 }} className={"bullet_cell"}>
                            <i className="fas fa-check"></i>{" "}
                          </td>
                          <td>
                            {d.title}
                          </td>
                          <td>
                            <a target="_blank" rel='noopener noreferer' href={d.url}> <strong> {d.downloadTitle} </strong> </a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
});

export default DownloadsPage;