import React, { useState } from "react"

//import NavigationMobile from './NavigationMobile';
import Navigation from "./Navigation"
import Footer from "./Footer"
//import { graphql, StaticQuery } from 'gatsby';

const HeaderBanner = () => {
  const [display, setDisplay] = useState("flex");
  const closeBanner = () => {
    setDisplay("none")
  };
  return (
    <div style={{ top: 0, backgroundColor: "#ef285d", padding: "10px 10px 5px 10px", justifyContent: "space-between", alignItems: "center", display }}>
      <p style={{ color: "#fff", textAlign: "justify", fontSize: "0.9rem", flexGrow: 1, margin: 0, padding: 0 }}>
        NEMCARE Hospital Tezpur never ask for any Advance against Doctor's Appointment and Room Booking. Please avoid such calls and e-mails asking for any amount.
      </p>
      <p style={{ color: "#fff", textAlign: "right", fontSize: "1.2rem", margin: 0, padding: "0 10px" }}>
        <a style={{ color: "#fff", }} href={"#"} onClick={closeBanner}>&times;</a>
      </p>
    </div>
  )
}

const Layout = props => {
  return (
    <>
      <HeaderBanner />
      <Navigation {...props} />

      {props.children}

      <div style={{ height: "10vh" }} />
      <Footer {...props} />
    </>
  )
}

export default Layout
